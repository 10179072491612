/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import * as yup from "yup";
import { useUpdateProfile } from "../../../../components/ProfilePage/hooks";
import { colors, fonts } from "../../../../constants";
import { Title } from "../../../../lib";
import Button from "../../../../lib/Button";
import FormField from "../../../../lib/forms/FormField";
import Input from "../../../../lib/forms/primitives/Input";
import { useHandleSubmitImpl } from "../../../../utils/forms";
import mediaQuery from "../../../../utils/mediaQuery";
import { memberIdRegexp } from "../../../../utils/regexp";
import renderError from "../../../../utils/renderError";
import { Session } from "../../../hooks/useAuthSession";
import Layout from "../Layout";
import MedicalCard from "./MedicalCard";

const StyledLayout = styled(Layout)``;
const Description = styled.p<{ $color: "black" | "gray" | "red" }>`
  color: ${({ $color }) =>
    // eslint-disable-next-line no-nested-ternary
    $color === "gray"
      ? colors.brownGrey
      : $color === "red"
      ? colors.red
      : colors.black};
  font-family: ${fonts.main}, sans-serif;
  font-size: 15px;
  line-height: 140%;
  margin: unset;

  & + & {
    margin-top: 10px;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 31px; // 32 does not fit
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
      line-height: 28px;
    `,
  )}
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  margin-top: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-direction: row;
      margin-top: 30px;
    `,
  )}
`;
const StyledFormField = styled(FormField)``;
const TopZone = styled.div`
  grid-area: top;
  padding-bottom: 32px;
  text-align: center;
  ${StyledFormField} {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding-bottom: 10px;
      text-align: start;
      width: 300px;
    `,
  )}
`;
const BottomZone = styled.div`
  background: ${colors.brownLightGrey2};
  grid-area: bottom;
  padding: 20px 20px 30px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      background: none;
      padding: 0;
      width: 300px;
    `,
  )}
`;
const CardZone = styled.div`
  background: ${colors.brownLightGrey2};
  grid-area: card;
  padding: 0 23px 24px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      background: none;
    `,
  )}
`;
const Content = styled.div`
  display: grid;
  grid-template-areas:
    "top"
    "bottom"
    "card";
  grid-template-columns: 1fr min-content;
  ${StyledTitle} {
    margin-bottom: 9px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 30px;
      `,
    )}
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      column-gap: 20px;
      grid-template-areas:
        "top card"
        "bottom card";
    `,
  )}
`;

const Form = styled.form`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  /* min-width: calc(50% - 10px); */
  flex-grow: 1;
  flex-shrink: 1;
  padding: 13px 16px;
  white-space: nowrap;
`;

const getResolver = () => {
  const FormSchema = yup.object().shape({
    memberId: yup
      .string()
      .trim()
      .required(t`can't be blank`)
      .matches(memberIdRegexp, t`Incorrect Member ID`),
  });

  return yupResolver(FormSchema);
};
interface FormProps {
  memberId: string;
}
export interface MemberIdScreenProps {
  closeMemberIdScreen: () => void;
  isJAMFUpdate: boolean;
}
const MemberIdScreen: FC<MemberIdScreenProps> = ({
  closeMemberIdScreen,
  isJAMFUpdate,
}) => {
  const {
    mutate: updateProfile,
    isLoading,
    error,
  } = useUpdateProfile();

  const resolver = useMemo(() => getResolver(), []);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      memberId: "",
    },

    resolver,
  });

  const { mutate: skipUpdateProfile, isLoading: skipInProgress } =
    useUpdateProfile();

  const queryClient = useQueryClient();
  const onSkip = useCallback(() => {
    skipUpdateProfile(
      {
        member_id: "",
        skip_member_id_update: true,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData<Session | undefined>(
            "cookie",
            (existingData) => {
              if (existingData && existingData.user) {
                return {
                  ...existingData,
                  user: {
                    ...existingData.user,
                    attributes: {
                      ...existingData.user.attributes,
                      skip_member_id_update: true,
                    },
                  },
                };
              }
              return existingData;
            },
          );
          closeMemberIdScreen();
        },
      },
    );
  }, [closeMemberIdScreen, queryClient, skipUpdateProfile]);

  const onSubmit = useCallback(
    ({ memberId }: FormProps) => {
      updateProfile(
        { member_id: memberId },
        {
          onSuccess() {
            // update existing session in place, without making additional requests
            queryClient.setQueryData<Session | undefined>(
              "cookie",
              (existingData) => {
                if (existingData?.user) {
                  return {
                    ...existingData,
                    user: {
                      ...existingData.user,
                      attributes: {
                        ...existingData.user.attributes,
                        member_id: memberId,
                      },
                    },
                  };
                }
                return existingData;
              },
            );
            closeMemberIdScreen();
          },
        },
      );
    },
    [closeMemberIdScreen, queryClient, updateProfile],
  );

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  const memberIdError =
    errors.memberId?.message || (error ? renderError(error) : null);
  return (
    <StyledLayout title="">
      <Content>
        <TopZone>
          <StyledTitle level="h1">
            {isJAMFUpdate ? (
              <Trans>Update your Member&nbsp;ID</Trans>
            ) : (
              <Trans>Add your Member&nbsp;ID</Trans>
            )}
          </StyledTitle>
          <Description $color="gray">
            {isJAMFUpdate ? (
              <Trans>
                You need to update your Member&nbsp;ID
                <br />
                to use fertility coverage.
              </Trans>
            ) : (
              <Trans>
                You need to add your Member&nbsp;ID to use
                fertility&nbsp;coverage.
              </Trans>
            )}
          </Description>
        </TopZone>
        <BottomZone>
          <Description $color="black">
            {isJAMFUpdate ? (
              <Trans>
                If you’re enrolled in the new medical plan with JAMF,
                please type in your Member&nbsp;ID from your BCBS
                medical card.
              </Trans>
            ) : (
              <Trans>
                If you&#39;re enrolled in a medical plan which
                includes Stork Club, please type in the Member&nbsp;ID
                from your regular medical insurance card. Stork Club
                will use it for verifying your eligibility and
                billing.
              </Trans>
            )}
          </Description>
          {!isJAMFUpdate && (
            <Description $color="red">
              <Trans>
                Skip this step if you&#39;re not enrolled in a medical
                plan with Stork Club coverage.
              </Trans>
            </Description>
          )}
          <Form onSubmit={handleSubmitImpl}>
            <StyledFormField
              error={memberIdError || undefined}
              hideLabel
              label={t`Member ID`}
            >
              <Input
                {...register("memberId")}
                $activeColor={colors.blueGreen}
                aria-invalid={!!memberIdError}
                autoComplete="cc-number"
                placeholder={t`Member ID`}
              />
            </StyledFormField>
            <Controls>
              <StyledButton
                kind="filledCoral"
                resetWidth
                type="submit"
              >
                {isJAMFUpdate ? (
                  <Trans>Update</Trans>
                ) : (
                  <Trans>Add</Trans>
                )}
              </StyledButton>
              <StyledButton
                disabled={isLoading}
                kind="outlinedCoral"
                loading={skipInProgress}
                onClick={onSkip}
                resetWidth
                type="button"
              >
                <Trans>Skip</Trans>
              </StyledButton>
            </Controls>
          </Form>
        </BottomZone>
        <CardZone>
          <MedicalCard />
        </CardZone>
      </Content>
    </StyledLayout>
  );
};

export default MemberIdScreen;
