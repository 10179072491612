import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import * as yup from "yup";
import FormField from "../../../../lib/forms/FormField";
import Input from "../../../../lib/forms/primitives/Input";
import { JSONApiErrorsException } from "../../../../network/jsonApi/core/JSONApiErrorsPayload";
import { mapJSONApiErrors } from "../../../../utils/errors";
import { useHandleSubmitImpl } from "../../../../utils/forms";
import mediaQuery from "../../../../utils/mediaQuery";
import { passwordRegexp } from "../../../../utils/regexp";
import { useUpdatePassword } from "../../hooks";
import { MarginButton } from "../style";

const getResolver = (minPasswordLength: number) => {
  const FormSchema = yup.object().shape({
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        t`doesn't match new password`,
      )
      .required(t`Please confirm your new password`),
    currentPassword: yup
      .string()
      .required(t`Please enter your current password`),
    newPassword: yup
      .string()
      .label("New password")
      .min(
        minPasswordLength,
        t`Password is too short (minimum is ${minPasswordLength} characters)`,
      )
      .matches(
        passwordRegexp,
        t`Password should contain lower and upper case letters and at least one number (0-9)`,
      )
      .required(t`Please enter your new password`),
  });

  return yupResolver(FormSchema);
};

interface FormProps {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type Props = {
  minPasswordLength: number;
  onSuccess: () => void;
};

const StyledFormField = styled(FormField)`
  width: 100%;

  ${mediaQuery(
    "desktopMedium",
    css`
      width: 50%;
    `,
  )}
`;
const StyledForm = styled.form`
  ${StyledFormField} {
    margin-top: 12px;
    &:nth-child(1) {
      padding-top: 12px;
    }
  }
`;

const ChangePasswordForm: FC<Props> = ({
  minPasswordLength,
  onSuccess,
}) => {
  const resolver = useMemo(
    () => getResolver(minPasswordLength),
    [minPasswordLength],
  );
  const {
    mutate: updatePassword,
    isLoading,
    error: updatePasswordError,
  } = useUpdatePassword();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormProps>({
    resolver,
  });

  useEffect(() => {
    if (updatePasswordError instanceof JSONApiErrorsException) {
      const parsedErrors = mapJSONApiErrors(
        updatePasswordError.errors,
        {
          confirmPassword: "data/attributes/password_confirmation",
          currentPassword: "data/attributes/current_password",
          newPassword: "data/attributes/password",
        },
      );

      for (const entry of Object.entries(parsedErrors)) {
        const error = entry[1];
        const key = entry[0] as keyof typeof parsedErrors;
        if (error?.title) {
          setError(key, {
            message: error.title,
          });
        }
      }
    }
  }, [setError, updatePasswordError]);

  const onSubmit = useCallback(
    ({
      currentPassword,
      confirmPassword,
      newPassword,
    }: FormProps) => {
      updatePassword(
        {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        },
      );
    },
    [onSuccess, updatePassword],
  );

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  return (
    <StyledForm onSubmit={handleSubmitImpl}>
      <StyledFormField
        error={errors.currentPassword?.message}
        hideLabel
        label={t`Current password`}
      >
        <Input
          {...register("currentPassword")}
          autoComplete="new-password"
          placeholder={t`Current password`}
          type="password"
        />
      </StyledFormField>
      <StyledFormField
        error={errors.newPassword?.message}
        hideLabel
        label={t`New password`}
      >
        <Input
          {...register("newPassword")}
          autoComplete="new-password"
          placeholder={t`New password`}
          type="password"
        />
      </StyledFormField>
      <StyledFormField
        error={errors.confirmPassword?.message}
        hideLabel
        label={t`Repeat password`}
      >
        <Input
          {...register("confirmPassword")}
          autoComplete="new-password"
          placeholder={t`Repeat password`}
          type="password"
        />
      </StyledFormField>

      <MarginButton
        disabled={isLoading}
        kind="filledCoral"
        resetWidth
        type="submit"
      >
        <Trans>Change password</Trans>
      </MarginButton>
    </StyledForm>
  );
};

export default ChangePasswordForm;
