/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { set } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import FormField from "../../../lib/forms/FormField";
import Checkbox from "../../../lib/forms/primitives/Checkbox";
import Input from "../../../lib/forms/primitives/Input";
import Radio from "../../../lib/forms/primitives/Radio";
import Select from "../../../lib/forms/primitives/Select";
import { signUpTokenNotDefined } from "../../../locales/errors";
import { JSONApiError } from "../../../network/jsonApi/core/JSONApiError";
import jsonApiErrorParser from "../../../utils/errors/jsonApiParser";
import scrollToErrorNode from "../../../utils/errors/scrollToErrorNode";
import squashErrors from "../../../utils/errors/squashErrors";
import {
  DAYS,
  GENDERS,
  SEX_OPTIONS,
  YEARS,
} from "../../../utils/formOptions";
import { useHandleSubmitImpl } from "../../../utils/forms";
import getMonthsForLocale from "../../../utils/getMonthsForLocale";
import { range } from "../../../utils/range";
import { passwordRegexp } from "../../../utils/regexp";
import { useSignUp } from "./hooks";
import {
  AlertLi,
  AlertUl,
  Description,
  DobSpacer,
  Fields,
  Grid2x,
  IsReadContainer,
  RadioGroup,
  StyledAlert,
  StyledButton,
  StyledLink,
  StyledRadioField,
} from "./SignUpForm.style";

const getFormSchema = (
  minPasswordLength: number,
  askEmployeeId: boolean,
) => {
  return yup.object().shape({
    base: yup.mixed().notRequired(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t`doesn't match Password`)
      .required(t`Confirm Password is a required field`),
    day: yup
      .number()
      .oneOf(DAYS.map((m) => m.value))
      .nullable()
      .required(t`Day is a required field`),
    employeeId: askEmployeeId
      ? yup
          .string()
          .trim()
          .required(t`Employee ID is a required field`)
      : yup.mixed().notRequired(),
    firstName: yup
      .string()
      .trim()
      .required(t`First name is a required field`),
    gender: yup
      .string()
      .oneOf(["male", "female", "other", "no_answer"])
      .nullable()
      .required(t`Gender is a required field`),
    isRead: yup
      .bool()
      .oneOf([true], t`Please read and accept to proceed`),
    lastName: yup
      .string()
      .trim()
      .required(t`Last name is a required field`),
    mobilePhone: yup
      .string()
      .label("mobile phone")
      .trim()
      .required(t`Mobile phone is a required field`),
    month: yup
      .number()
      .oneOf(range(1, 12))
      .nullable()
      .required(t`Month is a required field`),
    partnerEmail: yup
      .string()
      .email(t`Please enter a valid email address`)
      .notRequired(),
    // TODO: more strict validation according to the company policy
    password: yup
      .string()
      .required(t`Password is a required field`)
      .min(
        minPasswordLength,
        t`Password is too short (minimum is ${minPasswordLength} characters)`,
      )
      .matches(
        passwordRegexp,
        t`Password should contain lower and upper case letters and at least one number (0-9)`,
      ),
    preferredName: yup.string().trim().notRequired(),
    sex: yup
      .string()
      .oneOf(["male", "female"])
      .nullable()
      .required(t`Sex is a required field`),
    year: yup
      .number()
      .oneOf(YEARS.map((m) => m.value))
      .nullable()
      .required(t`Year is a required field`),
    // TODO: more strict validation
    zip: yup
      .string()
      .trim()
      .required(t`Zip is a required field`),
  });
};

export interface FormProps {
  employeeId?: string;
  firstName: string;
  preferredName: string;
  lastName: string;
  mobilePhone: string;
  zip: string;
  sex: "female" | "male";
  gender: "female" | "male" | "no_answer" | "other";
  month: number;
  day: number;
  year: number;
  password: string;
  confirmPassword: string;
  partnerEmail: string | null | undefined;
  isRead: boolean;
}

interface Props {
  isUS: boolean;
  minPasswordLength: number;
  emailSignUpToken: string | null;
  partnerInvitationToken: string | null;
  partnerInvitationEnabled: boolean;
  companyId: string;
  askEmployeeId: boolean;
  companyCareEmail: string | null;
}

const SignUpForm: FC<Props> = ({
  askEmployeeId,
  isUS,
  minPasswordLength,
  emailSignUpToken,
  partnerInvitationToken,
  partnerInvitationEnabled,
  companyId,
  companyCareEmail,
}: Props) => {
  const resolver = useMemo(() => {
    const formSchema = getFormSchema(
      minPasswordLength,
      askEmployeeId,
    );
    return yupResolver(formSchema);
  }, [askEmployeeId, minPasswordLength]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormProps>({
    resolver,
  });

  const {
    isLoading,
    mutate: signUp,
    error: externalError,
  } = useSignUp();

  const onSubmit = useCallback(
    (values: FormProps & { recaptchaToken: string }) => {
      if (!emailSignUpToken && !partnerInvitationToken) {
        // not possible on production - we redirect from this page anyway if token is not defined
        throw signUpTokenNotDefined;
      }
      const {
        employeeId,
        firstName,
        lastName,
        mobilePhone,
        zip,
        sex,
        gender,
        day,
        month,
        year,
        password,
        partnerEmail,
        preferredName,
        recaptchaToken,
      } = values;

      // TODO: validate date. Current behavior: 1993-02-31 becomes 1993-03-03
      const dob = set(new Date(), {
        date: day,
        month: month - 1,
        year,
      });
      signUp({
        companyId,
        dob,
        emailSignUpToken: emailSignUpToken || undefined,
        employeeId,
        firstName,
        gender,
        lastName,
        mobilePhone,
        partnerEmail,
        partnerInvitationToken: partnerInvitationToken || undefined,
        password,
        preferredName,
        recaptchaToken,
        sex,
        zip,
      });
    },
    [emailSignUpToken, partnerInvitationToken, signUp, companyId],
  );

  const recaptchaRef = useRef<ReCAPTCHABase>(null);

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);

  const parsedErrors = useMemo(() => {
    const error = externalError || internalError;

    if (!error) {
      return null;
    }
    const personalInfoErrorParser = (e: JSONApiError) =>
      e.code === "invalid_personal_info";

    const parser = jsonApiErrorParser({
      beneficiary: (e) => e.code === "beneficiary_not_registered_yet",
      confirmPassword: "data/attributes/password_confirmation",
      day: ["data/attributes/dob", personalInfoErrorParser],
      employeeId: [
        "data/attributes/employee_id",
        personalInfoErrorParser,
      ],
      firstName: ["data/attributes/fname", personalInfoErrorParser],
      gender: "data/attributes/gender",
      lastName: ["data/attributes/lname", personalInfoErrorParser],
      mobilePhone: "data/attributes/phone",
      month: ["data/attributes/dob", personalInfoErrorParser],
      partnerEmail: "data/attributes/partner_email",
      password: "data/attributes/password",
      personalInfo: personalInfoErrorParser,
      preferredName: "data/attributes/preferred_name",
      sex: "data/attributes/sex",
      year: ["data/attributes/dob", personalInfoErrorParser],
      zip: "data/attributes/zip",
    });

    return squashErrors(parser(error));
  }, [externalError, internalError]);

  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (!parsedErrors) {
      return;
    }
    const fieldNames = Object.keys(getValues()) as Array<
      keyof FormProps
    >;
    for (const fieldName of fieldNames) {
      const fieldError = parsedErrors.fieldErrors[fieldName];
      if (fieldError) {
        setError(fieldName, {
          message: fieldError,
        });
      }
    }

    scrollToErrorNode(
      formRef,
      parsedErrors.error,
      parsedErrors.fieldErrors,
      {
        __base__: document.querySelector(
          `[data-name="alert-main"]`,
        ) as HTMLElement,
        beneficiary: document.querySelector(
          `[data-name="alert-main"]`,
        ) as HTMLElement,
      },
    );
  }, [getValues, parsedErrors, setError]);

  const MONTHS = getMonthsForLocale().map((item, index) => ({
    label: item,
    value: index + 1,
  }));

  const usPolicyLinks = (
    <Trans>
      I have read and acknowledge both the{" "}
      <a
        href="https://joinstorkclub.com/legal/privacy"
        rel="noreferrer"
        target="_blank"
      >
        US Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href="https://joinstorkclub.com/legal/hipaa"
        rel="noreferrer"
        target="_blank"
      >
        HIPAA Notice & Consent
      </a>
      . I accept the{" "}
      <a
        href="https://joinstorkclub.com/legal/tos"
        rel="noreferrer"
        target="_blank"
      >
        Terms of Service
      </a>
      .
    </Trans>
  );

  const globalPolicyLinks = (
    <Trans>
      I have read and acknowledge the{" "}
      <a
        href="https://joinstorkclub.com/legal/privacy_global"
        rel="noreferrer"
        target="_blank"
      >
        Privacy Notice
      </a>
      . I accept the{" "}
      <a
        href="https://joinstorkclub.com/legal/tos"
        rel="noreferrer"
        target="_blank"
      >
        Terms of Service
      </a>
      .
    </Trans>
  );
  const { i18n } = useLingui();

  return (
    <form
      ref={formRef}
      css={`
        max-width: 768px;
      `}
      onSubmit={handleSubmitImpl}
    >
      <>
        <StyledAlert data-name="alert-main" status="error">
          {parsedErrors &&
            !parsedErrors.fieldErrors.personalInfo &&
            parsedErrors.fieldErrors.beneficiary}
          {parsedErrors?.error &&
            !parsedErrors.fieldErrors.personalInfo &&
            t`Please fill out or check the required fields below`}
          {parsedErrors?.error &&
            parsedErrors.fieldErrors.personalInfo &&
            t`Sorry, we couldn’t find your account with the information provided.`}
          {parsedErrors?.fieldErrors.personalInfo && (
            <AlertUl>
              {companyCareEmail && (
                <AlertLi>
                  <Trans>
                    Try again or reach out to us at{" "}
                    <b>{companyCareEmail}</b> to verify your
                    information.
                  </Trans>
                </AlertLi>
              )}
              <AlertLi>
                <Trans>
                  If you have already registered previously,{" "}
                  <StyledLink to="/signin">
                    log in at my.joinstorkclub.com
                  </StyledLink>
                </Trans>
              </AlertLi>
            </AlertUl>
          )}
        </StyledAlert>
        <Description>
          <Trans>
            To use your coverage, please enter your full name
            as&nbsp;it&nbsp;appears on your{" "}
            <b>medical ID card or other official ID</b>
          </Trans>
        </Description>
        <Fields>
          <Grid2x>
            <FormField
              error={errors.firstName?.message}
              label={t`First name`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("firstName")}
                $activeColor={colors.blueGreen}
              />
            </FormField>
            <FormField
              error={errors.lastName?.message}
              label={t`Last name`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("lastName")}
                $activeColor={colors.blueGreen}
              />
            </FormField>
            <FormField
              error={errors.preferredName?.message}
              label={t`Preferred name (optional)`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("preferredName")}
                $activeColor={colors.blueGreen}
              />
            </FormField>
          </Grid2x>
          <Grid2x>
            <FormField
              description={
                <>
                  <Trans>
                    We will send you appointment reminders
                  </Trans>
                </>
              }
              descriptionId="mobilePhone-hint"
              error={errors.mobilePhone?.message}
              label={t`Mobile phone`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("mobilePhone")}
                $activeColor={colors.blueGreen}
                aria-describedby="mobilePhone-hint"
              />
            </FormField>
            <FormField
              description={
                <>
                  <Trans>
                    We will show the best doctors in your area
                  </Trans>
                </>
              }
              descriptionId="zip-hint"
              error={errors.zip?.message}
              label={isUS ? t`ZIP` : t`Postal code`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("zip")}
                $activeColor={colors.blueGreen}
                aria-describedby="zip-hint"
              />
            </FormField>
            {askEmployeeId && (
              <FormField
                error={errors.employeeId?.message}
                label={t`Employee ID`}
                labelTextClassName="labelText"
              >
                <Input
                  {...register("employeeId")}
                  $activeColor={colors.blueGreen}
                />
              </FormField>
            )}
          </Grid2x>

          <StyledRadioField>
            <FormField
              error={errors.sex?.message}
              formGroup
              label={t`Biological sex`}
              layout="row"
            >
              <RadioGroup>
                {SEX_OPTIONS.map(({ label, value }) => (
                  <Radio
                    key={value}
                    css="margin-inline-end: 20px"
                    label={i18n._(label)}
                    value={value}
                    {...register("sex")}
                  />
                ))}
              </RadioGroup>
            </FormField>
          </StyledRadioField>
          <StyledRadioField>
            <FormField
              error={errors.gender?.message}
              formGroup
              label={t`Gender identity`}
              layout="row"
            >
              <RadioGroup>
                {GENDERS.map(({ label, value }) => (
                  <Radio
                    key={value}
                    css="margin-inline-end: 20px"
                    label={i18n._(label)}
                    value={value}
                    {...register("gender")}
                  />
                ))}
              </RadioGroup>
            </FormField>
          </StyledRadioField>
          <FormField
            error={
              (errors.month || errors.day || errors.year)?.message
            }
            formGroup
            label={t`Date of birth`}
            labelTextClassName="labelText"
          >
            <DobSpacer>
              <Controller
                control={control}
                name="month"
                render={({
                  field: { onBlur, onChange, value, ref },
                  fieldState,
                }) => (
                  <Select
                    ref={ref}
                    aria-errormessage={errors.month?.message}
                    aria-invalid={!!fieldState.error}
                    aria-label={t`Month`}
                    css="grid-area: month;"
                    isMulti={false}
                    isNetworkStyle
                    onBlur={onBlur}
                    onChange={(option) => onChange(option?.value)}
                    options={MONTHS}
                    placeholder={t`Month`}
                    value={MONTHS.find((m) => m.value === value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="day"
                render={({
                  field: { onBlur, onChange, value, ref },
                  fieldState,
                }) => (
                  <Select
                    ref={ref}
                    aria-errormessage={errors.day?.message}
                    aria-invalid={!!fieldState.error}
                    aria-label={t`Day`}
                    css="grid-area: day;"
                    isMulti={false}
                    isNetworkStyle
                    onBlur={onBlur}
                    onChange={(option) => onChange(option?.value)}
                    options={DAYS}
                    placeholder={t`Day`}
                    value={DAYS.find((m) => m.value === value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="year"
                render={({
                  field: { onBlur, onChange, value, ref },
                  fieldState,
                }) => (
                  <Select
                    ref={ref}
                    aria-errormessage={errors.year?.message}
                    aria-invalid={!!fieldState.error}
                    aria-label={t`Year`}
                    css="grid-area: year;"
                    isMulti={false}
                    isNetworkStyle
                    onBlur={onBlur}
                    onChange={(option) => {
                      onChange(option?.value);
                    }}
                    options={YEARS}
                    placeholder={t`Year`}
                    value={YEARS.find((m) => m.value === value)}
                  />
                )}
              />
            </DobSpacer>
          </FormField>
          <Grid2x>
            <FormField
              description={
                <>
                  <Trans>
                    Must have {minPasswordLength}+ characters in
                    length and contain lower and upper case letters
                    and at least one number (0-9)
                  </Trans>
                </>
              }
              descriptionId="password-hint"
              error={errors.password?.message}
              label={t`Create a password`}
              labelTextClassName="labelText"
            >
              <Input
                {...register("password")}
                $activeColor={colors.blueGreen}
                aria-describedby="password-hint"
                autoComplete="new-password"
                minLength={minPasswordLength}
                type="password"
              />
            </FormField>
            <FormField
              error={errors.confirmPassword?.message}
              label={t`Confirm password`}
              labelTextClassName="labelText"
            >
              {" "}
              <Input
                {...register("confirmPassword")}
                $activeColor={colors.blueGreen}
                autoComplete="new-password"
                type="password"
              />
            </FormField>
            {!partnerInvitationToken && partnerInvitationEnabled && (
              <FormField
                error={errors.partnerEmail?.message}
                label={t`Want to invite your partner? (can add later)`}
                labelTextClassName="labelText"
              >
                <Input
                  {...register("partnerEmail")}
                  $activeColor={colors.blueGreen}
                  autoComplete="off"
                  placeholder={t`Your partner's email`}
                  type="email"
                />
              </FormField>
            )}
          </Grid2x>

          <FormField error={errors.isRead?.message} label="">
            <IsReadContainer $hasError={!!errors.isRead}>
              <Checkbox
                {...register("isRead")}
                as="span"
                label=""
                networkStyle
              />
              <span>{isUS ? usPolicyLinks : globalPolicyLinks}</span>
            </IsReadContainer>
          </FormField>
        </Fields>
        <ReCAPTCHA ref={recaptchaRef} />
        <StyledButton
          disabled={isLoading}
          kind="filledBlueGreen"
          loading={isLoading}
          resetWidth
          type="submit"
        >
          <Trans>Sign up</Trans>
        </StyledButton>
      </>
    </form>
  );
};

export default SignUpForm;
