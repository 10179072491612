import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, { FC, useCallback, useMemo, useRef } from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import FormField, { Description } from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import { passwordRegexp } from "../../../utils/regexp";
import renderError from "../../../utils/renderError";
import { Header, StyledButton } from "../styles";
import { useResetPassword } from "./hooks";

interface Props {
  passwordMinLength?: number;
  resetPasswordToken: string;
  email?: string;
}

const StyledForm = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-top: 40px;
    `,
  )}

  ${StyledButton} {
    margin-top: 0;
  }

  ${Description} {
    color: #909090;
    font-size: 13px;
    line-height: 16px;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 355px;
    `,
  )}

  .labelText {
    margin-bottom: 12px;
  }
`;

const getFormSchema = (passwordMinLength: number) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t`Password required`)
      .min(
        passwordMinLength,
        t`Password must be at least ${passwordMinLength} characters long`,
      )
      .matches(
        passwordRegexp,
        t`Must have 8+ characters in length and contain lower and upper case letters and at least one number (0-9)`,
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t`doesn't match Password`),
  });
};

interface FormProps {
  password: string;
  passwordConfirmation: string;
}

const ChangePasswordForm: FC<Props> = ({
  passwordMinLength = 8,
  resetPasswordToken,
  email,
}: Props) => {
  const navigate = useNavigate();

  const resolver = useMemo(
    () => yupResolver(getFormSchema(passwordMinLength)),
    [passwordMinLength],
  );
  const {
    isLoading,
    error: externalError,
    mutate: resetPassword,
  } = useResetPassword();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },

    resolver,
  });
  const recaptchaRef = useRef<ReCAPTCHABase>(null);
  const onSubmit = useCallback(
    ({
      password,
      passwordConfirmation,
      recaptchaToken,
    }: {
      password: string;
      passwordConfirmation: string;
      recaptchaToken: string;
    }) => {
      resetPassword(
        {
          password,
          passwordConfirmation,
          recaptchaToken,
          resetPasswordToken,
        },
        {
          onSuccess: () => {
            navigate("/");
          },
        },
      );
    },
    [navigate, resetPassword, resetPasswordToken],
  );
  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  return (
    <>
      {!!error && <Alert status="error">{renderError(error)}</Alert>}
      <Header>
        <Trans>Change password</Trans>
      </Header>
      <StyledForm onSubmit={handleSubmitImpl}>
        <input
          autoComplete="username"
          name="email"
          type="hidden"
          value={email}
        />
        <FormField
          description={
            <Trans>
              Must have {passwordMinLength}+ characters in length and
              contain lower and upper case letters and at least one
              number (0-9)
            </Trans>
          }
          error={errors.password?.message}
          label={t`Create a password`}
          labelTextClassName="labelText"
        >
          <Input
            {...register("password")}
            $activeColor={colors.blueGreen}
            autoComplete="new-password"
            minLength={passwordMinLength}
            type="password"
          />
        </FormField>
        <FormField
          error={errors.passwordConfirmation?.message}
          label={t`Confirm password`}
          labelTextClassName="labelText"
        >
          <Input
            {...register("passwordConfirmation")}
            $activeColor={colors.blueGreen}
            autoComplete="new-password"
            type="password"
          />
        </FormField>
        <StyledButton
          css="white-space: nowrap;"
          disabled={isLoading}
          kind="filledBlueGreen"
          type="submit"
        >
          <Trans>Change password</Trans>
        </StyledButton>
      </StyledForm>
      <ReCAPTCHA ref={recaptchaRef} />
    </>
  );
};

export default ChangePasswordForm;
