import { RefObject } from "react";
import {
  FieldErrors,
  ScrollToErrorNodes,
  SupportedFormControls,
} from "./types";

function isFormControl(
  element: unknown,
): element is SupportedFormControls {
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLSelectElement
  );
}
/**
 * Check if object is a React ref
 */
function isRef<T = unknown>(
  obj: unknown,
): obj is RefObject<T extends RefObject<infer V> ? RefObject<V> : T> {
  return (
    obj !== null &&
    typeof obj === "object" &&
    Object.prototype.hasOwnProperty.call(obj, "current")
  );
}

export default function scrollToErrorNode(
  form: HTMLFormElement | RefObject<HTMLFormElement>,
  formError: string | null,
  fieldErrors: FieldErrors,
  nodes: ScrollToErrorNodes,
) {
  function scrollImpl(scrollNode: HTMLElement): void {
    // We want to scroll to the form field if possible to show the label as well
    const formField = scrollNode.closest(".form-field");

    const scrollTarget = formField || scrollNode;
    scrollTarget.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  const mainAlertNode =
    // eslint-disable-next-line no-nested-ternary
    nodes && nodes.__base__
      ? isRef(nodes.__base__)
        ? nodes.__base__.current
        : nodes.__base__
      : undefined;
  if (formError && mainAlertNode) {
    console.debug("scrolling to main alert node", {
      formError,
      mainAlertNode,
    });
    scrollImpl(mainAlertNode);
    return;
  }

  const fieldErrorsCopy = { ...fieldErrors };
  let formFieldToScroll: SupportedFormControls | undefined;

  const formElements =
    (isRef(form) ? form.current?.elements : form.elements) || [];
  for (const field of formElements) {
    if (!isFormControl(field) || !field.name) {
      continue;
    }
    // The field to scroll is not defined yet and the field has an error
    if (fieldErrorsCopy[field.name] && !formFieldToScroll) {
      formFieldToScroll = field;
    }
    // remove handled field from the copy
    delete fieldErrorsCopy[field.name];
  }

  if (nodes) {
    // find virtual fields - fields that have an error but do not exist in the form
    for (const [fieldName] of Object.entries(fieldErrorsCopy)) {
      const nodeArg = nodes[fieldName];
      const node = isRef(nodeArg) ? nodeArg.current : nodeArg;
      if (node) {
        console.debug("scrolling to virtual node", fieldName);
        scrollImpl(node);
        return;
      }
    }
  }
  if (formFieldToScroll) {
    console.debug("scrolling to form field", formFieldToScroll.name);
    scrollImpl(formFieldToScroll);
  }
}
