import { Trans } from "@lingui/macro";
import { AnimatePresence, motion } from "framer-motion";
import React, { CSSProperties } from "react";
import { useLocation, Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import { colors } from "../../constants";
import Icon from "../../lib/SharedIcon";
import longTimer from "../../utils/longTimer";
import mediaQuery from "../../utils/mediaQuery";
import { useGetCallsLive, useGetCallsUpcoming } from "./hooks";

const PhoneOuter = keyframes`
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(0.9);
    }
`;

const CallNotification = styled(motion.div)`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  bottom: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
    0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${colors.brownBlack};
  display: flex;
  flex-direction: row-reverse;
  gap: 13px;
  height: 56px;
  margin: 0 9px;
  padding: 10px;
  position: fixed;
  z-index: 1000;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      bottom: 20px;
      flex-direction: row;
      inset-inline-end: 20px;
      margin: 0;
      max-width: calc(100vw - 240px - 40px);
      padding: 10px;
      padding-inline-start: 15px;
    `,
  )}
`;

const Text = styled.div`
  font-size: 15px;

  a {
    color: ${colors.blue};
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${colors.green};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;

  &::before {
    animation: ${PhoneOuter} 3000ms infinite;
    background-color: rgba(33, 205, 151, 0.2);
    border-radius: 50%;
    content: "";
    filter: blur(2px);
    height: 42px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 42px;
    z-index: -1;
  }
`;

const CallNotifications: React.FC<{
  className?: string;
  style?: CSSProperties;
}> = (props) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isCallSoon, setIsCallSoon] = React.useState<boolean>(false);

  const { data: upcomingCallsData } = useGetCallsUpcoming();
  const { data: liveCallsData } = useGetCallsLive(isCallSoon);

  React.useEffect(() => {
    const abortController = new AbortController();

    if (upcomingCallsData && upcomingCallsData.data.length > 0) {
      const time = upcomingCallsData.data[0].attributes.booked_at;
      const targetTime = new Date(time.getTime() - 10 * 60 * 1000); // 10 minutes before call
      const interval = 60 * 1000;
      longTimer(targetTime, interval, abortController.signal)
        .then(() => {
          setIsCallSoon(true);
        })
        .catch(() => {});
    }

    return () => {
      abortController.abort();
    };
  }, [upcomingCallsData]);

  React.useEffect(() => {
    if (liveCallsData && liveCallsData.data.length > 0) {
      setIsCallSoon(false);
      setIsActive(true);
    }
  }, [liveCallsData]);

  // don't show at call pages
  if (
    pathname.startsWith("/join/") ||
    pathname === "/pages/download_apps"
  ) {
    return null;
  }

  if (!isActive) return null;

  const link =
    liveCallsData?.data[0].attributes.deep_url ||
    "/pages/download_apps";

  return (
    <AnimatePresence>
      <CallNotification
        animate={{ y: 0 }}
        initial={{ y: 200 }}
        {...props}
      >
        <IconContainer>
          <Icon
            height="18px"
            mainColor="#fff"
            type="camera"
            width="18px"
          />
        </IconContainer>
        <Text>
          <Trans>Your scheduled call has started.</Trans>{" "}
          <Link to={link}>
            <Trans>Open Stork Club mobile app to join the call</Trans>
          </Link>
        </Text>
      </CallNotification>
    </AnimatePresence>
  );
};

export default CallNotifications;
